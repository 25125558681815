import { Check, RefreshCw, X } from 'lucide-react'
import * as React from 'react'
import { useSpinDelay } from 'spin-delay'
import { cn } from '#app/utils/misc.tsx'
import { Button, type ButtonProps } from './button.tsx'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './tooltip.tsx'

export const StatusButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    status: 'pending' | 'success' | 'error' | 'idle'
    message?: string | null
    spinDelay?: Parameters<typeof useSpinDelay>[1]
  }
>(({ message, status, className, children, spinDelay, ...props }, ref) => {
  const delayedPending = useSpinDelay(status === 'pending', {
    delay: 400,
    minDuration: 300,
    ...spinDelay,
  })
  const companion = {
    pending: delayedPending ? (
      <RefreshCw className="h-6 w-6 animate-spin" />
    ) : null,
    success: <Check className="h-6 w-6" />,
    error: <X className="text-destructive-foreground h-6 w-6" />,
    idle: null,
  }[status]

  return (
    <Button
      ref={ref}
      className={cn('flex justify-center gap-4', className)}
      {...props}
    >
      {children}
      {message ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>{companion}</TooltipTrigger>
            <TooltipContent>{message}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        companion
      )}
    </Button>
  )
})
StatusButton.displayName = 'Button'
